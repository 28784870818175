*{
  box-sizing: border-box;
}

body{
  color:#393939;
}

h1,h2,h3,h4,h5{
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
}

/* a:hover{
  opacity: 0.7;
} */

a{
  text-decoration:"none";
  cursor:pointer
}

nav span{
  position: relative;
}

nav span::before{
  content:'';
  position: absolute;
  left:50%;
  width:0;
  height:3px;
  bottom:-5px;
  background: #4771DF;
  transform:translate(-50%);
  transition:width .2s cubic-bezier(.215,.61,.355,1);
}

nav a:hover{
  color:#4771DF;
}

nav span:hover::before{
  width: 100%;
}

.section-content{
  max-width: 1300px;
  margin:0 auto;
}

@media screen and (max-width: 900px) {

  .section-content{
    max-width: 95%;
    margin:0 auto;
  }

  .pc{
    display:none !important;
  }
}